import $ from 'jquery';
window.jQuery = $;

import './vendor/fullpage';
import './vendor/owl.carousel';
import './vendor/slick'
import './vendor/validation'
import './vendor/inputmask'
import './vendor/simple'
import './vendor/svguse'
// import './vendor/jquery.lazy'
import './vendor/jquery.magnific-popup'
import './vendor/jquery.liMarquee'
import './vendor/jquery-ui.min';

import './polyfill/padStart';

import './global/sliders'
import './pages/main'
import './global/tooltip'