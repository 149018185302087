import $ from 'jquery';

$(document).ready(function () {
  $('#first-tags').simplemarquee({
    speed: 50,
    cycles: Infinity,
    direction: 'right',
  });

  $('#second-tags').simplemarquee({
    speed: 50,
    cycles: Infinity,
  });

  $('#third-tags').simplemarquee({
    speed: 50,
    cycles: Infinity,
    direction: 'right',
  });

  if ($('.owl-item').length) {
    if ($('.owl-item.active').index() === $('.owl-item').length - 1) {
      it.carousel.trigger('to.owl.carousel', [0, 200]);
    }
  }

  if ($('#services').length) {
    $('.section.tags').addClass('slide');
  }

  // слайдер отзывов
  const $reviewsSlider = $('.reviews-slider');
  $reviewsSlider
    .on('init reInit breakpoint', function () {
      $reviewsSlider.append(`
            <div class="slider__numb">
                <div class="slider__this"></div>
                <div class="slider__total"></div>
            </div>
        `);
    })
    .on('init reInit afterChange', function (event, slick, currentSlide) {
      const i = (currentSlide ? currentSlide : 0) + 1;
      $reviewsSlider.find('.slider__numb').removeClass('animation');
      setTimeout(() => {
        $reviewsSlider.find('.slider__numb').addClass('animation');
        $reviewsSlider.find('.slider__this').text(String(i).padStart(2, '0'));
        $reviewsSlider
          .find('.slider__total')
          .text(String(slick.slideCount).padStart(2, '0'));
      }, 0);
    })
    .slick({
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 15000,
      cssEase: 'ease-in-out',
      adaptiveHeight: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            touchMove: false,
          },
        },
      ],
    });

  // слайдер новостей на главной
  const $newsSlider = $('.news-main-slider');
  const VIEW_SLIDER = 3;
  const TABLE_BREAK_POINT = 1024;
  const MAX_SLIDE = 4;
  $newsSlider
    .on('init reInit breakpoint', function (event, slick, currentSlide) {
      let countViewSlider;
      if (window.innerWidth > TABLE_BREAK_POINT - 1) {
        countViewSlider = VIEW_SLIDER;
      } else {
        countViewSlider = 1;
      }
      if (slick.slideCount > countViewSlider) {
        $newsSlider.append(`
                <div class="slider__numb">
                    <div class="slider__this"></div>
                    <div class="slider__total"></div>
                </div>
            `);
      }
    })
    .on('init reInit afterChange', function (event, slick, currentSlide) {
      let i = 0;
      let countSliders = 0;
      let countViewSlider;
      if (window.innerWidth > TABLE_BREAK_POINT - 1) {
        countViewSlider = VIEW_SLIDER;
      } else {
        countViewSlider = 1;
      }

      if (slick.slideCount > countViewSlider) {
        if (window.innerWidth > TABLE_BREAK_POINT - 1) {
          i =
            (currentSlide && currentSlide < MAX_SLIDE
              ? Math.floor(currentSlide / VIEW_SLIDER)
              : 0) + 1;
          countSliders = Math.ceil(slick.slideCount / VIEW_SLIDER);
        } else {
          i = (currentSlide ? currentSlide : 0) + 1;
          countSliders = slick.slideCount;
        }

        $newsSlider.find('.slider__numb').removeClass('animation');
        setTimeout(() => {
          $newsSlider.find('.slider__numb').addClass('animation');
          $newsSlider.find('.slider__this').text(String(i).padStart(2, '0'));
          $newsSlider
            .find('.slider__total')
            .text(String(countSliders).padStart(2, '0'));
        }, 0);
      }
    })
    .slick({
      slidesToShow: VIEW_SLIDER,
      slidesToScroll: VIEW_SLIDER,
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 15000,
      cssEase: 'ease-in-out',
      responsive: [
        {
          breakpoint: TABLE_BREAK_POINT,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: false,
          },
        },
      ],
    });

  // слайдер в проектах
  $('.project-slider').each(function (i, el) {
    const $slider = $(el);
    $slider
      .on('init reInit breakpoint', function () {
        $slider.append(`
                <div class="slider__numb">
                    <div class="slider__this"></div>
                    <div class="slider__total"></div>
                </div>
            `);
      })
      .on('init reInit afterChange', function (event, slick, currentSlide) {
        const i = (currentSlide ? currentSlide : 0) + 1;
        $slider.find('.slider__numb').removeClass('animation');
        setTimeout(() => {
          $slider.find('.slider__numb').addClass('animation');
          $slider.find('.slider__this').text(String(i).padStart(2, '0'));
          $slider
            .find('.slider__total')
            .text(String(slick.slideCount).padStart(2, '0'));
        }, 0);
      })
      .slick({
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnFocus: false,
        pauseOnHover: false,
        cssEase: 'ease-in-out',
      });
  });
});

window.addEventListener('load', (event) => {
  const clientsSlider = document.querySelector('#client-slider');

  if (!clientsSlider) {
    return;
  }

  const logoImages = clientsSlider.querySelectorAll('img');
  let isLoaded;
  for (let i = 0; i < logoImages.length; i++) {
    isLoaded = logoImages[i].complete && logoImages[i].naturalHeight !== 0;
  }
  if (isLoaded) {
    $('#client-slider').liMarquee({
      direction: 'left',
      loop: -1,
      scrolldelay: 0,
      scrollamount: 120,
      circular: true,
      drag: false,
      hoverstop: false,
      runshort: true,
    });
  }
});
