import $ from 'jquery';
import AOS from 'aos';
import { loadScripts } from '../global/loadScript';

$(document).ready(function () {
  // preloader
  const preloader = document.querySelector('.preloader');

  function getCookie(name) {
    let matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  if (getCookie('visit') === undefined) {
    const path = document.querySelector('.preloader__path');
    const secondPath = document.querySelector('.preloader__second-path');

    preloader.style.display = 'flex';

    setTimeout(function () {
      path.classList.add('hide');
      secondPath.classList.add('active');
    }, 4500);

    setTimeout(function () {
      preloader.style.display = 'none';
      document.cookie = 'visit=true';
      AOS.init();
    }, 5500);
  } else {
    AOS.init();
  }

  let start = performance.now();

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / 3200; // 3800
    if (timeFraction > 1) timeFraction = 1;

    let Val = document.querySelector('.preloader__text');

    let oldVal = 0,
      newVal = 100;

    let numb = Math.ceil(oldVal + (newVal - oldVal) * timeFraction) + '';
    let len = numb.length;

    Val.innerHTML = len === 1 ? '00' + numb : len === 2 ? '0' + numb : numb;

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  });

  // menu
  $(window).scroll(function () {
    let height = $(window).height();

    if ($(this).scrollTop() > height / 2) {
      $('.scroll-menu').addClass('active');
    } else {
      $('.scroll-menu').removeClass('active');
    }
  });

  $(window).on('load resize', function () {
    if ($(window).width() > 1023) {
      $.scrollify({
        section: '.slide',
        //sectionName : "section-name",
        // очищаем хеши прокрутки для SEO
        sectionName: '',
        scrollSpeed: 1000,
        easing: 'easeOutExpo',
        overflowScroll: true,
        offset: 0,
      });
    } else {
      $.scrollify.destroy();
    }
  });

  // магия с анимацией проектов
  $(window).scroll(function () {
    if ($('.projects').length) {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windHeight = window.innerHeight;
      const projTop = $('.projects').offset().top - 1;
      const projHeight = $('.projects').height();
      const lastSlide = projTop + projHeight - windHeight;
      const offset = (scrollTop - projTop) / (windHeight / 100);
      let color = $('.slides-list__item').eq(0).attr('data-color');

      $('.slides-list').css({ background: color });

      if (scrollTop > projTop - windHeight * 0.3) {
        if (offset / 1.6 > 50) {
          color = $('.slides-list__item').eq(1).attr('data-color');
        }
        if (offset / 1.6 > 100) {
          color = $('.slides-list__item').eq(2).attr('data-color');
        }

        if (scrollTop > projTop) {
          $('.slides-list-wrap').addClass('fixed');
        } else {
          $('.slides-list-wrap').removeClass('fixed');
        }

        if (scrollTop > lastSlide) {
          $('.slides-list-wrap').addClass('bottom');
        } else {
          $('.slides-list-wrap')
            .addClass('show')
            .removeClass('bottom')
            .css({ background: color });
        }

        $('.slides-list').css({ background: color, left: -offset / 1.4 + '%' });
      } else {
        $('.slides-list-wrap')
          .removeClass('show fixed bottom')
          .css({ background: '' });
        $('.slides-list').css({ left: '' });
      }
    }
  });

  // переключение технологий
  $('.tech-list__item').on('click', function (e) {
    e.preventDefault();
    let self = $(this);
    let number = self.data('num');

    $('.tech-slider__item').each((index, item) => {
      if ($(item).data('num') === number) {
        $('.tech-slider__item').removeClass('active');
        $(item).addClass('active');

        $('.tech-list__item').removeClass('active');
        self.addClass('active');
      }
    });
  });

  $('.tech-list__link_mob').magnificPopup({
    type: 'inline',
    mainClass: 'tech-slider-popup',
    callbacks: {
      beforeOpen: function () {
        setTimeout(() => {
          $('html, body').scrollTop(0);
          $('html').addClass('open-modal');
          const $techSlider = $('#tech-slider');
          const activeSlide = $('.tech-list__item.active').data('num');
          $techSlider.find('.tech-slider__list').addClass('owl-carousel');

          function sliderCounter(e) {
            const totalItems = e.item.count;
            const currentIndex = e.item.index + 1;
            $techSlider.find('.slider__numb').removeClass('animation');

            setTimeout(() => {
              $techSlider.find('.slider__numb').addClass('animation');
              $techSlider
                .find('.slider__this')
                .text(String(currentIndex).padStart(2, '0'));
              $techSlider
                .find('.slider__total')
                .text(String(totalItems).padStart(2, '0'));
            }, 0);
          }

          $techSlider.find('.tech-slider__list').owlCarousel({
            smartSpeed: 500,
            slideTransition: 'ease-in-out',
            nav: true,
            autoplay: true,
            autoplayTimeout: 5000,
            startPosition: Math.abs(activeSlide) - 1,
            items: 1,
            dots: false,
            mouseDrag: false,
            touchDrag: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            onInitialized: sliderCounter,
            onTranslated: sliderCounter,
          });

          $techSlider.append(`
                        <div class="slider__numb">
                            <div class="slider__this"></div>
                            <div class="slider__total"></div>
                        </div>
                    `);
        }, 0);
      },
      beforeClose: function () {
        $('html').removeClass('open-modal');
        $('#tech-slider')
          .find('.tech-slider__list')
          .trigger('destroy.owl.carousel');
        $('#tech-slider').find('.slider__numb').remove();
      },
    },
  });

  // плейсхолдеры
  $('.request-form__input, .request-form__file').on('change', function () {
    if (this.value) {
      $(this).closest('.request-form__wrap').addClass('completed');
    } else {
      $(this).closest('.request-form__wrap').removeClass('completed');
    }
  });

  $('.request-form__input')
    .on('focus blur', function () {
      $(this).closest('.request-form__wrap').toggleClass('focus');
    })
    .on('mouseover', function () {
      $(this).closest('.request-form__wrap').addClass('hover');
    })
    .on('mouseout', function () {
      $(this).closest('.request-form__wrap').removeClass('hover');
    });

  // файл в форме
  $('input[name="name"]').inputmask({ regex: '[a-zA-Zа-яА-Я--]*' });

  $('input[type=file]').change(function (e) {
    if (e.target.files.length) {
      if (e.target.files[0].size >= 10000000) {
        $(this).val('');
        $(this).closest('.request-form__wrap').removeClass('completed');
        $('.request-form__text-input').addClass(
          'request-form__text-input_error'
        );
        $('.request-form__input-fake').text('');
      } else {
        $('.request-form__input-fake')
          .text(e.target.files[0].name)
          .addClass('active');
        $('.request-form__text-input').removeClass(
          'request-form__text-input_error'
        );
      }
    } else {
      $('.request-form__input-fake').text('').removeClass('active');
    }
  });

  $('input[type="tel"]').inputmask({
    mask: '+9{11,14}',
    placeholder: '',
    clearMaskOnLostFocus: true,
    clearIncomplete: true,
  });

  // валидация
  $.validator.addMethod(
    'emailText',
    function (value, element) {
      return (
        this.optional(element) || /^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/.test(value)
      );
    },
    'Некорректный адрес'
  );

  const $form = $('.request-form');

  $form.on('submit', (e) => {
    e.preventDefault();
  });

  $form.validate({
    submitHandler: function (form, event) {
      $('.contacts__form').hide();
      $('.form-loader').show();
      let formData = new FormData();
      formData.append('file', $('input[type="file"]')[0].files[0]);
      formData.append('name', $form.find("input[name='name']").val());
      formData.append('email', $form.find("input[name='email']").val());
      formData.append('phone', $form.find("input[name='phone']").val());
      formData.append('task', $form.find("textarea[name='task']").val());
      formData.append('_token', $form.find("input[name='_token']").val());
      let captchaOpenKey = $('.request-form').data('captcha');
      //обновляем значение в капче
      grecaptcha
        .execute(captchaOpenKey, { action: 'contact' })
        .then(function (token) {
          var recaptchaResponse = document.getElementById('recaptchaResponse');
          if (recaptchaResponse) {
            recaptchaResponse.value = token;
            formData.append(
              'recaptcha_response',
              $('#recaptchaResponse').val()
            );
          }
        })
        .then(function () {
          $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            contentType: false,
            processData: false,
            data: formData,
            success: function (data) {
              $('.form-loader').hide();
              $('.form-success').show();
            },
            error: function (error) {
              console.log(error);
              $('.form-loader').hide();
              $('.form-error').show();
            },
            complete: function () {
              //обновляем значение в капче
              grecaptcha
                .execute(captchaOpenKey, { action: 'contact' })
                .then(function (token) {
                  var recaptchaResponse =
                    document.getElementById('recaptchaResponse');
                  if (recaptchaResponse) {
                    recaptchaResponse.value = token;
                  }
                })
                .then(function () {
                  $form.find('input').removeClass('request-valid');
                  $('.request-form__submit').attr('disabled', '');
                });
            },
          });
        });
    },

    invalidHandler: function (event, validator) {
      console.error('Форма заполнена с ошибками!');
    },

    highlight: function (element, errorClass, validClass) {
      $(element).addClass(errorClass).removeClass(validClass);
      $(element)
        .closest('.request-form__wrap')
        .addClass('request-form__wrap_alert');

      if (
        $('[name="name"]').hasClass('request-valid') &&
        $('[name="email"]').hasClass('request-valid') &&
        $('[name="task"]').hasClass('request-valid')
      ) {
        $('.request-form__submit').removeAttr('disabled');
      } else {
        $('.request-form__submit').attr('disabled', '');
      }
    },

    unhighlight: function (element, errorClass, validClass) {
      $(element).removeClass(errorClass).addClass(validClass);
      if (
        $('[name="name"]').hasClass('request-valid') &&
        $('[name="email"]').hasClass('request-valid') &&
        $('[name="task"]').hasClass('request-valid')
      ) {
        $('.request-form__submit').removeAttr('disabled');
      } else {
        $('.request-form__submit').attr('disabled', '');
      }
      $(element)
        .closest('.request-form__wrap')
        .removeClass('request-form__wrap_alert');
    },

    errorElement: 'span',

    errorClass: 'request-error',

    validClass: 'request-valid',

    rules: {
      name: {
        required: true,
      },
      email: {
        required: true,
        emailText: true,
      },
      number: {},
      task: {
        required: true,
      },
    },
  });

  $form.on('input', '[name="task"]', function (e) {
    if (
      $('[name="name"]').hasClass('request-valid') &&
      $('[name="email"]').hasClass('request-valid') &&
      $(e.target).val() !== ''
    ) {
      $('.request-form__submit').removeAttr('disabled');
    } else {
      $('.request-form__submit').attr('disabled', '');
    }
  });

  $('.js-form-button-send').on('click', function () {
    $form[0].reset();
    $('.request-form__input-fake')
      .text('Прикрепить файл')
      .removeClass('active');

    $('.form-success, .form-error').hide();
    $('.contacts__form').show();
  });

  // меню

  $('.scroll-menu__burger').on('click', function () {
    $('.menu').toggleClass('open');
    $('.scroll-menu').toggleClass('open');
  });

  const path = window.location.pathname;
  $('.nav__item').removeClass('active');
  if (path.indexOf('contact') !== -1) {
    $('[data-name="contact"]').addClass('active');
  }
  if (path.indexOf('services') !== -1) {
    $('[data-name="services"]').addClass('active');
  }
  if (path.indexOf('blog') !== -1) {
    $('[data-name="blog"]').addClass('active');
  }
  if (path.indexOf('works') !== -1) {
    $('[data-name="works"]').addClass('active');
  }

  // отзывы в модалке
  $('.open-img').magnificPopup({
    type: 'image',
    fixedContentPos: true,
    callbacks: {
      beforeOpen: function () {
        $('html').addClass('open-modal');
      },
      beforeClose: function () {
        $('html').removeClass('open-modal');
      },
    },
  });

  $('.arrow-down').click(function () {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('.technology').offset().top,
      },
      1500
    );
  });

  const $licensesTrigger = $('.js-popup-licenses');

  $licensesTrigger.magnificPopup({
    key: 'licenses-popup',
    type: 'inline',
    callbacks: {
      beforeOpen: function () {
        $('html').addClass('open-modal');
      },
      beforeClose: function () {
        $('html').removeClass('open-modal');
      },
    },
  });

  $('.popup__button').on('click', function () {
    $.magnificPopup.close();
  });

  const $textAreaTask = $('.js-task-textarea');

  $textAreaTask.on('keyup', function () {
    if (this.scrollTop > 0) {
      this.style.height = this.scrollHeight + 'px';
    }
  });

  // TODO
  let contactsMap;
  const mapBlock = document.querySelector('.contacts #map');
  const shopListDataInput = document.querySelector('.js-contacts-bullets-data');
  const shopListDataValue = JSON.parse(shopListDataInput.value);
  const isRussian = mapBlock.dataset.russian === 'true';

  loadScripts(
    `https://api-maps.yandex.ru/2.1/?lang=${isRussian ? 'ru_RU' : 'en_US'}`,
    (status) => {
      if (status && mapBlock) {
        const renderBullets = () => {
          shopListDataValue.forEach(function (element) {
            contactsMap.geoObjects.add(new ymaps.Placemark(element.point));
          });
        };
        function init() {
          let centerPointer = shopListDataValue[0].point;

          contactsMap = new ymaps.Map(
            'map',
            {
              center: centerPointer,
              zoom: 7,
              controls: [],
            },
            {
              suppressMapOpenBlock: true,
            }
          );

          renderBullets();

          contactsMap
            .setBounds(contactsMap.geoObjects.getBounds(), {
              checkZoomRange: true,
              zoomMargin: 9,
            })
            .then(function () {
              if (contactsMap.getZoom() > 14) contactsMap.setZoom(14);
            });

          contactsMap.behaviors.disable('scrollZoom');
          //центровка карты по всем точкам
        }

        ymaps.ready(init);
      }
    }
  );
});

$(window).resize(function () {
  $.magnificPopup.close();
});
